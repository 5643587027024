import React, { Component } from 'react';
import { get, isEmpty, isPlainObject, omit } from 'lodash';
import { Icon, Popover } from 'antd';
import ContentEditable from 'react-contenteditable';
import { StyledTable, RemoveIcon } from './CustomSectionTable.styled';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import moment from 'moment';
import sectionModalCancel from '../sections/sectionModalCancel';
import {
  ATTRIBUTE_TYPE_SWITCH,
  ATTRIBUTE_TYPE_DATETIME,
  ATTRIBUTE_TYPE_DATE,
  ATTRIBUTE_TYPE_TIME,
  ATTRIBUTE_TYPE_STRING,
  ATTRIBUTE_TYPE_LINK,
  ATTRIBUTE_TYPE_CUSTOM,
} from '../sections/constants';
import {
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_TIME_FORMAT,
  BENCHMARK_DATE_TIME_FORMAT,
  formatDateStringOrTimestamp,
} from '../../../utils/parse-date';
import getEHRecords from './getEHRecords';
import buttonDefinition from './ButtonDefinition';
import { PERMISSIONS } from '../../../utils/admin';

const MAX_COLUMNS = 5;
const MAX_ROWS = 5;
const COMMENT_FIELD = 'comment';

class CustomSectionTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      showTransferModal: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      propsHasChanged(nextProps, this.props) ||
      propsHasChanged(nextState, this.state)
    );
  }

  componentDidUpdate(previousProps, previousState) {
    const {
      editSection,
      mainForm,
      sectionId,
      prefix,
      attributeId,
      isSectionReadOnly = false,
    } = this.props;
    if (!isEmpty(editSection) && this.state.showModal !== true) {
      if (editSection.sectionId === sectionId) {
        const keys = get(
          mainForm,
          `values.${prefix}[${sectionId}].${attributeId}`,
          []
        );
        const index = keys.findIndex(k => k === editSection.key);

        if (index !== -1) {
          this.showModal(
            sectionId,
            index,
            { key: editSection.key },
            isSectionReadOnly,
            true
          );
        }
      }
    }
  }

  showModal = (sectionId, rowIndex, row, readOnly, isNew = false) => {
    this.setState({
      showModal: true,
      sectionId,
      rowIndex,
      row,
      readOnly,
      isNew,
    });
  };

  showTransferModal = (sectionId, rowIndex, row, readOnly, isNew = false) => {
    this.setState({
      showTransferModal: true,
      sectionId,
      rowIndex,
      row,
      readOnly,
      isNew,
    });
  };

  handleModalOk = (...params) => {
    const { setEditSectionTable, onModalOkCallback } = this.props;
    const { isNew } = this.state;
    if (isNew) {
      setEditSectionTable();
    }

    if (onModalOkCallback) {
      onModalOkCallback(...params, this.props);
    }

    this.setState({
      showModal: false,
      isNew: false,
    });
  };

  handleModalCancel = e => {
    const { readOnly, row, isNew } = this.state;
    if (!readOnly) {
      sectionModalCancel(isNew, row, this.props);
    }

    this.setState({
      showModal: false,
      isNew: false,
    });
  };

  handleTransferModalOk = e => {
    this.setState({
      showTransferModal: false,
    });
  };

  handleTransferModalCancel = e => {
    this.setState({
      showTransferModal: false,
    });
  };

  getCommentObjectWithPopOver = commentObject => {
    return {
      ...commentObject,
      render: comment => (
        <Popover placement="topLeft" content={comment} title="Comment">
          {comment}
        </Popover>
      ),
    };
  };

  renderDatetime = formatDateStringOrTimestamp;

  sorterDateTime = attributeName => (a, b) =>
    moment(a[`${attributeName}`] || 0).unix() -
    moment(b[`${attributeName}`] || 0).unix();

  renderTypeString = (validValues, isArray) => {
    if (validValues && !isArray) {
      const isObject = isPlainObject(validValues[0]);
      return (value = '') => {
        if (isObject) {
          const val = validValues.find(v => v.value === value);
          return (
            <ContentEditable
              className="text"
              html={val ? val.label : value}
              disabled={true}
            />
          );
        } else {
          return (
            <ContentEditable
              className="text"
              html={validValues.find(v => v === value)}
              disabled={true}
            />
          );
        }
      };
    } else {
      return (value = '') => (
        <ContentEditable className="text" html={value} disabled={true} />
      );
    }
  };

  sorterTypeString = (validValues, isArray, attributeName) => (a, b) => {
    if (validValues && !isArray) {
      const isObject = isPlainObject(validValues[0]);
      const aValue = validValues.find(v => v.value === a[`${attributeName}`]);
      const bValue = validValues.find(v => v.value === b[`${attributeName}`]);
      if (isObject && aValue && bValue) {
        return aValue.label && bValue.label
          ? aValue.label.localeCompare(bValue.label)
          : aValue.localeCompare(bValue);
      } else {
        return (a[`${attributeName}`] || '').localeCompare(
          b[`${attributeName}`] || ''
        );
      }
    } else {
      return (a[`${attributeName}`] || '').localeCompare(
        b[`${attributeName}`] || ''
      );
    }
  };

  renderTypeLink = linkAction => (text, record, index) => (
    <a
      onClick={event => {
        linkAction(event, index);
      }}
    >
      {text}
    </a>
  );

  sorterTypeLink = attributeName => (a, b) =>
    (a[`${attributeName}`] || '').localeCompare(b[`${attributeName}`] || '');

  getColumnsFromAttributes = attrs => {
    const {
      sectionId,
      maxColumns = MAX_COLUMNS,
      linkAction,
      ellipsis = true,
      sortable = true,
    } = this.props;

    return attrs
      .map(
        (
          { type, validValues, settings, title, attributeName, isArray },
          index
        ) => {
          const extra = {
            sortDirections: settings.sortDirections || ['descend', 'ascend'],
            defaultSortOrder: settings.defaultSortOrder,
          };
          switch (type) {
            case ATTRIBUTE_TYPE_SWITCH:
              extra.render = bool => (bool ? 'Yes' : 'No');
              extra.sorter = (a, b) =>
                a[`${attributeName}`] - b[`${attributeName}`];
              break;
            case ATTRIBUTE_TYPE_DATETIME:
              extra.render = datetime =>
                this.renderDatetime(datetime, BENCHMARK_DATE_TIME_FORMAT);
              extra.sorter = this.sorterDateTime(attributeName);
              break;
            case ATTRIBUTE_TYPE_DATE:
              extra.render = date =>
                this.renderDatetime(date, BENCHMARK_DATE_FORMAT);
              extra.sorter = this.sorterDateTime(attributeName);
              break;
            case ATTRIBUTE_TYPE_TIME:
              extra.render = time =>
                this.renderDatetime(time, BENCHMARK_TIME_FORMAT);
              extra.sorter = this.sorterDateTime(attributeName);
              break;
            case ATTRIBUTE_TYPE_STRING:
              extra.render = this.renderTypeString(validValues, isArray);
              extra.sorter = this.sorterTypeString(
                validValues,
                isArray,
                attributeName
              );
              break;
            case ATTRIBUTE_TYPE_LINK:
              extra.render = this.renderTypeLink(linkAction);
              extra.sorter = this.sorterTypeLink(attributeName);
              break;
            case ATTRIBUTE_TYPE_CUSTOM:
              extra.render = settings.render;
              extra.sorter = settings.sorter;
              break;
          }
          const finalExtra = sortable
            ? extra
            : omit(extra, ['sorter', 'sortDirections']);

          const tableHeader = settings.tableHeader
            ? settings.tableHeader
            : title;
          const tableEllipsis =
            attributeName === COMMENT_FIELD ? false : ellipsis;

          if ('width' in settings) {
            finalExtra.width = settings.width;
          }

          return {
            title: tableHeader,
            dataIndex: attributeName,
            key: `${sectionId}-${index}`,
            ellipsis: tableEllipsis,
            ...finalExtra,
          };
        },
        []
      )
      .slice(0, maxColumns);
  };

  addAction = () => {
    const {
      sectionId,
      onAction,
      mainForm,
      prefix,
      isSectionReadOnly = false,
      showIconAction = false,
      iconAction = 'plus-circle',
      actionLabel = 'Details',
      actionAttributes = [],
      disableAction = false,
      isContributor = false,
      currentUser = null,
    } = this.props;
    const detailActionIcon = showIconAction ? (
      <RemoveIcon type={iconAction} />
    ) : (
      actionLabel
    );

    const buttonParameters = {
      altText: 'Action',
      keyNaming: `${sectionId}-action`,
      actionName: 'default',
      targetIcon: detailActionIcon,
    };

    const createdBy = get(
      mainForm,
      `values.${prefix}[${sectionId}].createdBy`,
      []
    );

    const columns = [
      buttonDefinition(
        this.showModal,
        showIconAction,
        onAction,
        sectionId,
        isSectionReadOnly,
        buttonParameters,
        this, // parent context for the callback
        disableAction,
        isContributor,
        currentUser,
        createdBy
      ),
    ];

    actionAttributes
      .filter(attr => attr.field === PERMISSIONS.transferEmployment)
      .forEach(attr => {
        const customActionIcon = showIconAction ? (
          <Icon type="right-circle" style={{ fontSize: '16px' }} />
        ) : (
          'Transfer'
        );

        const transferButtonParameters = {
          altText: attr.label,
          keyNaming: `${sectionId}-action-${attr.field}`,
          actionName: attr.field,
          targetIcon: customActionIcon,
        };

        const generatedButton = buttonDefinition(
          this.showTransferModal,
          showIconAction,
          onAction,
          sectionId,
          null,
          transferButtonParameters
        );

        columns.push(generatedButton);
      });

    return columns;
  };

  render = () => {
    const {
      attributes = [],
      mainForm,
      sectionId,
      prefix,
      attributeId,
      modalFor,
      hasAction = true,
      onClickRow,
      rowSelection,
      maxRows = MAX_ROWS,
      buildModal,
      buildTransferModal,
      loading = false,
      className = '',
      rowClassName = () => {},
    } = this.props;

    const { attrs, dataSource } = getEHRecords(
      mainForm,
      prefix,
      sectionId,
      attributeId,
      attributes
    );

    let columns = this.getColumnsFromAttributes(attrs);

    columns.push({
      title: '',
      dataIndex: 'index',
      key: `${sectionId}-index`,
      width: 0,
      render: () => <></>,
    });

    if (hasAction) {
      columns = [...columns, ...this.addAction()];
    }

    const scroll = dataSource.length > maxRows ? { y: 250 } : {};
    const footer = () => `Total number of records: ${dataSource.length}`;
    const onRow =
      onClickRow &&
      ((record, rowIndex) => {
        return {
          onClick: event => {
            onClickRow(rowIndex, record);
          },
        };
      });

    const itemSectionId =
      modalFor === 'sections'
        ? this.state.sectionId
        : get(this.state, 'row.key');

    const modal =
      buildModal &&
      buildModal(
        this.state.readOnly,
        this.state.showModal,
        itemSectionId,
        this.state.rowIndex,
        this.handleModalCancel,
        this.handleModalOk,
        this.state.isNew,
        prefix
      );

    const transferModal =
      buildTransferModal &&
      buildTransferModal(
        this.state.showTransferModal,
        itemSectionId,
        this.state.rowIndex,
        this.handleTransferModalCancel,
        this.handleTransferModalOk
      );

    //PPL-461 adding popOver to comment column.
    const COMMENT = 'comment';
    const commentIndexColumn = columns.findIndex(
      element => element.dataIndex === COMMENT
    );
    if (commentIndexColumn !== -1) {
      const commentObject = columns[commentIndexColumn];
      const commentObjectWithPopOver = this.getCommentObjectWithPopOver(
        commentObject
      );
      columns[commentIndexColumn] = commentObjectWithPopOver;
    }

    return (
      <>
        <StyledTable
          className={className}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          scroll={scroll}
          footer={footer}
          onRow={onRow}
          rowSelection={rowSelection}
          loading={loading}
          rowClassName={rowClassName}
        />
        {modal}
        {transferModal}
      </>
    );
  };
}

export default CustomSectionTable;
