import { get } from 'lodash';

const getContributorSectionHistory = props => {
  const {
    contributorSectionId,
    selectedForm = {},
    parentKey,
    parentIndex,
  } = props;
  const __assignedSectionsHistory = get(
    selectedForm,
    ['data', '__assignedSectionsHistory'],
    []
  );
  const sectionId = get(
    selectedForm,
    ['data', parentKey, parentIndex, 'id'],
    contributorSectionId
  );

  return __assignedSectionsHistory.filter(h => h.id === sectionId);
};

export default getContributorSectionHistory;
