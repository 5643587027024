import React, { useEffect, useState } from 'react';
import { initialize } from 'redux-form';
import { get } from 'lodash';
import { Button } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import { getCurrentUser, getAgencyTZ } from 'APP_ROOT/selectors/session';
import getRoute from 'APP_ROOT/utils/get-route';

import AdministratorWrapper from '../Administrator.styled';
import caseFileEndpoints from '../../../api/caseFileEndpoints/caseFileEndpoints';

import { StyledTable } from './AgencyCasefiles.styled';
import getTableConfig from './getTableConfig';
import { NOTIFICATION_ERROR, FORM_NAME, showNotification } from './constants';
import CaseFileTypeModal from './components/caseFileTypeForm/CaseFileTypeModal';

const AgencyCasefiles = React.forwardRef(
  ({ dispatch, timezone, agencyId, tenantId, history }, _) => {
    const [casefileTypes, setCasefileTypes] = useState([]);
    const [loadingTypes, setLoadingTypes] = useState(false);
    const [CFTModalVisible, setCFTModalVisible] = useState(false);
    const tableConfig = getTableConfig(timezone, handleClick);

    useEffect(() => {
      fetchData();
    }, []);

    function fetchData() {
      setLoadingTypes(true);
      caseFileEndpoints
        .getCasefileTypes(tenantId)
        .then(response => {
          if (response?.content.length > 0) {
            const types = response.content.map(type => {
              return {
                key: type.id,
                type: htmlUnescape(type.name),
                category: type.categoryName,
                categoryId: type.categoryId,
                abbreviation: type.abbreviation,
                createdAt: type.createdAt,
                deadline: type.deadline,
                allowsDeletion: type.allowsDeletion,
              };
            });
            setCasefileTypes(types);
          }
        })
        .catch(err => {
          const message = err.message || 'API response is not ok';
          showNotification(NOTIFICATION_ERROR, 'Error', message);
        })
        .finally(() => setLoadingTypes(false));
    }

    function handleClick(casefileType) {
      dispatch(initialize(FORM_NAME, { casefileType }));
      if (casefileType.key) {
        history.push(
          getRoute('administratorAgencyCasefileType', {
            agencyId,
            tenantId,
            casefileTypeId: casefileType.key,
          })
        );
      }
    }

    const handleToggleModal = visible => setCFTModalVisible(visible);

    return (
      <AdministratorWrapper>
        <PageHeader
          title="Case File Types"
          goBackTo={getRoute('administratorAgency', { agencyId, tenantId })}
          actions={[
            <Button
              type="primary"
              icon="plus"
              onClick={() => handleToggleModal(true)}
            >
              Create New Case File Type
            </Button>,
          ]}
        ></PageHeader>
        <StyledTable
          style={{ padding: '24px' }}
          columns={tableConfig}
          dataSource={casefileTypes}
          loading={loadingTypes}
          bordered
        />
        <CaseFileTypeModal
          isVisible={CFTModalVisible}
          handleToggleModal={handleToggleModal}
          afterCreationAction={fetchData}
        />
      </AdministratorWrapper>
    );
  }
);

const mapState = (state, props) => {
  const user = getCurrentUser(state);
  const agencyId = get(props, 'match.params.agencyId');
  const tenantId = get(props, 'match.params.tenantId');
  const timezone = getAgencyTZ(state);
  return {
    user,
    agencyId,
    tenantId,
    timezone,
  };
};

export default withoutClutter(DashboardPage(mapState)(AgencyCasefiles));
