import React from 'react';
import { isArray, isEmpty, isString } from 'lodash';
import { Row, Col, Skeleton } from 'antd';
import { orderBy } from 'lodash';

import Section from '../../../../../components/form-viewer/styled/section';

import SectionAttribute from '../SectionAttribute';
import {
  ATTRIBUTE_TYPE_TEXTAREA,
  FULL_ROW_WIDTH,
  HALF_ROW_WIDTH,
} from '../UserProfile.constants';

const CASE_FILE_OVERVIEW_OWNER_ATTRIBUTE_ID =
  '7277816f-0b90-4d98-9c8b-76e0b64ad99e';

const COLUMNS_PER_ROW = 2;

const UserProfileSectionForm = ({
  profileForm,
  sectionId,
  prefix,
  attributes = [],
  allowedAttributes = {},
  isSectionReadOnly = false,
  isModal = false,
  timezone = 'UTC',
  rowIndex,
  loading = false,
  children,
  renderOwnerWarningIcon = false,
}) => {
  const renderAttributes = (
    attributes,
    useFullRow = false,
    useTitle = true,
    loading = false
  ) => {
    const columnSize = useFullRow ? FULL_ROW_WIDTH : HALF_ROW_WIDTH;

    return attributes.map(
      ({
        attributeId,
        attributeName,
        title,
        type,
        validValues,
        unique,
        disabled = false,
        allowClear = false,
        isArray,
        settings,
        readOnly = false,
        editable = true,
        onChange = () => {},
        disabledDate = () => {},
        onDeselect = () => {},
        onFocus = () => {},
        onSearch = () => {},
        onSelect = () => {},
        formatDateWithoutTimezone = null,
        filterOption = false,
        forceEnable = false,
      }) => {
        const isCaseFileOwnerField =
          attributeId === CASE_FILE_OVERVIEW_OWNER_ATTRIBUTE_ID;
        return (
          <Col
            key={`col-${attributeId}`}
            xs={24}
            sm={columnSize}
            md={columnSize}
            lg={columnSize}
          >
            {loading && <Skeleton active />}
            {!loading && (
              <SectionAttribute
                key={attributeId}
                name={
                  isModal
                    ? `${prefix}.${sectionId}.${attributeName}[${rowIndex}]`
                    : `${prefix}.${sectionId}.${attributeName}`
                }
                title={useTitle ? title : ''}
                type={type}
                validValues={
                  settings && settings.isSorted
                    ? sortList(validValues)
                    : validValues
                }
                maxLength={settings && settings.maxLength}
                ellipsisLength={settings && settings.ellipsisLength}
                unique={unique}
                profileForm={profileForm}
                isArray={isArray}
                allowClear={allowClear}
                onDeselect={onDeselect}
                formatDateWithoutTimezone={formatDateWithoutTimezone}
                readOnly={
                  forceEnable
                    ? false
                    : !allowedAttributes[attributeName] &&
                      (isSectionReadOnly || readOnly || !editable)
                }
                disabled={!allowedAttributes[attributeName] && disabled}
                onChange={onChange}
                onFocus={onFocus}
                onSearch={onSearch}
                onSelect={onSelect}
                showSearch={settings ? settings.showSearch : false}
                timezone={timezone}
                disabledDate={disabledDate}
                filterOption={filterOption}
                isCaseFileOwnerField={isCaseFileOwnerField}
                renderOwnerWarningIcon={renderOwnerWarningIcon}
              />
            )}
          </Col>
        );
      }
    );
  };

  const renderRows = (sectionId, attributes) => {
    const totalAttrs = attributes.length;
    const rows = getRows(attributes);
    return (
      <Section>
        {rows.map((row, i) => {
          const { type } = row[0];
          const isTextarea = type === ATTRIBUTE_TYPE_TEXTAREA;
          const useTitle = !(isTextarea && totalAttrs === 1);
          return (
            <Row key={`row-${sectionId}-${i}`}>
              {renderAttributes(row, isTextarea, useTitle, loading)}
            </Row>
          );
        })}
        {children}
      </Section>
    );
  };

  return renderRows(sectionId, attributes);
};

function getRows(attributes) {
  const attrs = orderBy(
    attributes,
    ['settings.formOrder', 'settings.formColumn'],
    ['asc', 'asc']
  );
  // group columns per row based
  let rows = [];
  let i = 0;
  const totalAttrs = attrs.length;
  while (i < totalAttrs) {
    let count = COLUMNS_PER_ROW;
    let cols = [];
    while (count > 0 && i < totalAttrs) {
      const { type } = attrs[i];
      if (type === ATTRIBUTE_TYPE_TEXTAREA) {
        if (cols.length === 0) {
          cols.push(attrs[i]);
          i++;
        }
        count = 0;
      } else {
        cols.push(attrs[i]);
        i++;
        count--;
      }
    }
    rows.push(cols);
  }
  return rows;
}

function sortList(list) {
  if (isArray(list) && !isEmpty(list) && isString(list[0])) {
    return list.sort();
  }
  return list;
}

export default UserProfileSectionForm;
