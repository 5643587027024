import React from 'react';
import moment from 'moment';
import ContentEditable from 'react-contenteditable';

import parseDate, {
  BENCHMARK_DATE_TIME_FORMAT,
} from 'APP_ROOT/utils/parse-date';

function renderDate(data, timezone) {
  if (data) {
    const date = parseDate(
      moment.utc(data).toISOString(),
      timezone,
      BENCHMARK_DATE_TIME_FORMAT
    );
    return `${date}`;
  }
  return '';
}

const renderString = value => {
  if (value) {
    return <ContentEditable html={value} disabled={true} />;
  }
  return '';
};

export default timezone => [
  {
    title: 'Report',
    dataIndex: 'resourceInstance',
    key: 'resourceInstance',
    sorter: false,
    fixed: 'left',
    width: 150,
  },
  {
    title: 'Timestamp',
    dataIndex: 'date',
    key: 'date',
    sorter: false,
    render: data => renderDate(data, timezone),
    width: 200,
  },
  {
    title: 'Executed By',
    dataIndex: 'executedByFullname',
    key: 'executedByFullname',
    render: renderString,
    sorter: false,
    width: 150,
  },
  {
    title: 'Event Type',
    dataIndex: 'eventType',
    key: 'eventType',
    sorter: false,
    width: 150,
  },
  {
    title: 'Results',
    dataIndex: 'result',
    key: 'result',
    sorter: false,
    width: 150,
  },
  {
    title: 'Details',
    dataIndex: 'details',
    key: 'details',
    render: renderString,
    sorter: false,
    width: 300,
  },
];
