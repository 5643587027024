import styled from 'styled-components';
import IconButton from 'APP_COMPONENTS/common/buttons/icon-button.js';

const StyledIconButton = styled(IconButton)`
  margin-top: -5px;
  margin-left: 12px;

  &:focus {
    background-color: #273b55;
  }
`;

export default StyledIconButton;
