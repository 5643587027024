import { hasPermissions, PERMISSIONS } from 'APP_ROOT/utils/admin';

export default props => {
  const { isOwner, editable, id, permissions } = props;
  const manageAllCasefiles = hasPermissions(
    permissions,
    PERMISSIONS.manageAllCasefiles
  );
  const manageMyCasefiles = hasPermissions(
    permissions,
    PERMISSIONS.manageMyCasefiles
  );
  const isValidOwner = isOwner && manageMyCasefiles;

  return (isValidOwner || manageAllCasefiles) && editable && !!id;
};
