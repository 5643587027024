import React, { useCallback, useEffect, useState } from 'react';
import { Button, Menu, Checkbox, Dropdown, Icon, Row } from 'antd';
import styled from 'styled-components';
import preferences from '../../preferences/columns';

const { Item, Divider } = Menu;

const Title = styled.h3`
  font-weight: 600;
  padding: 6px 12px 0;
`;

const MenuRow = styled(Row)`
  padding: 6px 12px;
`;

const MenuButton = styled(Button)`
  font-size: 14px;
  border-radius: 4px;
  padding: 4px 10px;
`;

const COLUMNS_KEYS = {
  casefileId: 'Case File ID',
  name: 'Title',
  description: 'Description',
  createdByFullName: 'Owner',
  createdAt: 'Created Date',
  individual: 'Individuals',
  reasons: 'Reasons',
  outcomes: 'Outcomes',
  deadline: 'Deadline',
  status: 'Status',
};

const COLUMNS = Object.keys(COLUMNS_KEYS).reduce(
  (prev, curr) => ({
    ...prev,
    [curr]: { checked: curr === 'casefileId' },
  }),
  {}
);

const ColumnsPreferences = ({ onApply, onReset }) => {
  const [columns, setColumns] = useState(COLUMNS);
  const [allChecked, setAllChecked] = useState(false);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const { getColumns } = preferences;
    const _columns = getColumns();
    if (_columns) {
      setColumns(_columns);
    }
  }, []);

  useEffect(() => {
    const isAllChecked = isAllEntriesChecked();
    if (isAllChecked) {
      setAllChecked(true);
    }
  }, [columns]);

  const handleToggleAll = () => {
    if (allChecked) {
      setColumns(prevState =>
        Object.keys(prevState).reduce(
          (prev, curr) => ({
            ...prev,
            [curr]: { checked: curr === 'casefileId' ? true : false },
          }),
          prevState
        )
      );
      setAllChecked(false);
    } else {
      setColumns(prevState =>
        Object.keys(prevState).reduce(
          (prev, curr) => ({
            ...prev,
            [curr]: { checked: true },
          }),
          prevState
        )
      );
      setAllChecked(true);
    }
  };

  const handleCheckboxClick = _key => {
    setColumns(prevState =>
      Object.entries(prevState).reduce((prev, curr) => {
        const [key, value] = curr;
        if (_key !== key) return { ...prev, [key]: value };
        return { ...prev, [key]: { checked: !value.checked } };
      }, prevState)
    );
  };

  const isAllEntriesChecked = () => {
    let counter = 0;
    const keys = Object.keys(COLUMNS_KEYS);
    keys.forEach(key => {
      if (!columns[key]?.checked) {
        counter++;
      }
    });
    return counter === 0;
  };

  const handleOnReset = () => {
    setColumns(prevState =>
      Object.keys(prevState).reduce(
        (prev, curr) => ({
          ...prev,
          [curr]: { checked: curr === 'casefileId' ? true : false },
        }),
        prevState
      )
    );
    setAllChecked(false);
    handleVisibleChange(false);
    onReset();
  };

  const handleOnApply = () => {
    handleVisibleChange(false);
    onApply(columns);
  };

  const handleVisibleChange = flag => setVisible(flag);

  const menu = useCallback(
    () => (
      <Menu>
        <Title>Table Preferences</Title>
        <Divider />
        <Item>
          <Checkbox checked={allChecked} onChange={() => handleToggleAll()}>
            Select All Columns / Deselect All Columns
          </Checkbox>
        </Item>
        {Object.entries(COLUMNS_KEYS).map(([key, value]) => (
          <Item key={`column-preferences-${key}`}>
            <Checkbox
              checked={columns[key]?.checked}
              disabled={key === 'casefileId'}
              onChange={() => handleCheckboxClick(key)}
            >
              {value}
            </Checkbox>
          </Item>
        ))}
        <Divider />
        <MenuRow type="flex" align="middle" justify="space-between">
          <MenuButton
            type="danger"
            size="small"
            onClick={() => handleOnReset()}
          >
            Reset
          </MenuButton>
          <MenuButton
            type="primary"
            size="small"
            onClick={() => handleOnApply()}
          >
            Apply
          </MenuButton>
        </MenuRow>
      </Menu>
    ),
    [columns, allChecked]
  );

  return (
    <Dropdown
      overlay={menu}
      visible={visible}
      trigger={['click']}
      onVisibleChange={handleVisibleChange}
    >
      <a onClick={e => e.preventDefault()}>
        <Icon type="setting" theme="outlined" style={{ fontSize: 32 }} />
      </a>
    </Dropdown>
  );
};

export default ColumnsPreferences;
