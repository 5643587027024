import { get } from 'lodash';

const getReportUsers = report => {
  return Object.assign(
    {},
    get(report, 'data.__users'),
    Object.values(get(report, 'participants', [])).reduce(
      (_users, user) => Object.assign({}, _users, { [user.id]: user }),
      {}
    )
  );
};

export default getReportUsers;
