import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Tooltip } from 'antd';
import { get, sortBy } from 'lodash';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import createModal from 'APP_ROOT/actions/create-modal';
import showModal from 'APP_ROOT/actions/show-modal';
import { getAgencyTZ } from 'APP_ROOT/selectors/session';
import { momentWithTZ } from 'APP_ROOT/utils/parse-date';

import ModalTitle from 'APP_COMPONENTS/common/modal/title.js';
import ModalBody from 'APP_COMPONENTS/common/modal/body.js';
import FormHistoryTimeline from 'APP_COMPONENTS/form-history-timeline';

import getOnBehalfUsers from '../../../utils/getOnBehalfUsers';
import getReportUsers from '../../../utils/getReportUsers';

import getContributorSectionHistory from '../utils/getContributorSectionHistory';
import { CONTRIBUTOR_ASSIGNMENT_SECTION } from '../../../../constants/contributeToReport';

import StyledIconButton from './ContributorTimeline.styled';

export class ContributorTimeline extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  viewHistory = e => {
    e.stopPropagation();

    const { timezone, dispatch, selectedForm = {} } = this.props;
    const participants = get(selectedForm, 'meta.participants', []);
    const sectionHistory = getContributorSectionHistory(this.props);
    const history = sortBy(sectionHistory, item =>
      momentWithTZ(item.createdAt, timezone)
    );

    const Title = <ModalTitle>Contributor Section Timeline</ModalTitle>;

    const usersOnBehalf = getOnBehalfUsers(selectedForm);
    const users = getReportUsers({ ...selectedForm, participants });
    const recordWithReport = {
      history,
      timezone,
      usersOnBehalf,
      report: selectedForm,
      participants: users,
      customTimeline: CONTRIBUTOR_ASSIGNMENT_SECTION,
    };

    const Body = (
      <ModalBody>
        <FormHistoryTimeline {...recordWithReport} />
      </ModalBody>
    );
    const modalOptions = {
      id: 'crt-timeline-modal', // could use sectionId or repItemId as sufix
      visible: true,
      title: Title,
      children: Body,
    };
    dispatch(createModal(modalOptions));
    dispatch(showModal(modalOptions.id));
  };

  render() {
    const historyIcon = require('APP_ROOT/icons/ic_history_crt.svg');

    return (
      <Tooltip title="Timeline">
        <StyledIconButton
          key="timeline"
          shape="circle"
          onClick={this.viewHistory}
          className="timeline"
        >
          <img src={historyIcon} alt="Timeline" />
        </StyledIconButton>
      </Tooltip>
    );
  }
}

const mapState = (state, props) => {
  const selectedForm = get(state, 'form.selectedForm', {});
  const timezone = getAgencyTZ(state);

  return {
    timezone,
    selectedForm,
  };
};

export default connect(mapState)(ContributorTimeline);
