import React from 'react';
import { Modal } from 'antd';
import CaseFileTypeForm from './CaseFileTypeForm';

const CaseFileTypeModal = ({
  casefileType,
  timezone,
  isVisible,
  isCreatingCaseFile = true,
  handleToggleModal = () => {},
  afterCreationAction = () => {},
  afterDeleteAction = () => {},
}) => {
  return (
    <Modal
      title="Case File Type Configuration"
      visible={isVisible}
      onCancel={() => handleToggleModal(false)}
      footer={null}
    >
      <CaseFileTypeForm
        casefileType={casefileType}
        closeModal={() => handleToggleModal(false)}
        afterCreationAction={afterCreationAction}
        afterDeleteAction={afterDeleteAction}
        isCreatingCaseFile={isCreatingCaseFile}
        timezone={timezone}
      />
    </Modal>
  );
};

export default CaseFileTypeModal;
