import { get } from 'lodash';

import { RESET_CTR_SECTION_REQUEST } from '.';
import resetCRTSectionSuccess from './reset-ctr-section-success';
import resetCRTSectionError from './reset-ctr-section-error';

import {
  resetCRTSection,
  resetCRTRepeaterSection,
} from '../components/form-viewer/field-types/services/api';

export default (formId, sectionId, repeaterId, next = () => {}) => {
  return (dispatch, getState) => {
    const {
      session: { currentUser: { agencyId } = {} } = {},
      form: {
        selectedForm: { resetingCTR },
      },
    } = getState();
    const resetCRT = repeaterId ? resetCRTRepeaterSection : resetCRTSection;

    dispatch({ type: RESET_CTR_SECTION_REQUEST });

    return new Promise((resolve, reject) => {
      if (agencyId && !resetingCTR) {
        resetCRT(agencyId, formId, sectionId, repeaterId)
          .then(async response => {
            await Promise.all([dispatch(resetCRTSectionSuccess(response))]);
            next(null, response);
          })
          .catch(error => {
            dispatch(resetCRTSectionError(get(error, ['response', 'status'])));
            next(error);
          });
      } else {
        reject();
      }
    });
  };
};
