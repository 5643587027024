import React, { Component } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { omit, isEmpty, get, set } from 'lodash';
import { Button, Icon } from 'antd';

import ModalTitle from 'APP_ROOT/components/common/modal/title';
import withModal from 'APP_ROOT/components/common/modal/base';
import syncFormData from 'APP_ROOT/actions/sync-form-data';

import getErrorMessageModalBody from '../../../utils/getErrorMessageModalBody';

import { mapProperties } from '../../../utils/form';
import SectionWrapperDiv from '../styled/section-wrapper';
import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';

import { getContributeToReportVisibilityLevels } from './utils/contribute-to-report-object-types';
import eventEmitter, { EventTypes } from '../../../utils/eventEmitter.js';

import componentsManager from '../../../modules/FormBuilder/services/componentsManager';
import getAllFieldsFlat from '../../../modules/FormBuilder/utils/getAllFieldsFlat';
import {
  CONTRIBUTOR_ASSIGNMENT_SECTION,
  CONTRIBUTOR_SECTION_COMPLETE,
} from '../../../constants/contributeToReport';
import { FIELD } from 'APP_ROOT/constants/layoutComponentTypes.js';
import { CHECKBOX } from 'APP_ROOT/constants/fieldTypes';

import validateSectionWrapperFields from './utils/validateSectionWrapperFields';
import { getErrors } from '../forms/utils/get-errors-utils';

class SectionWrapper extends withModal(Component) {
  ctrCompleteButtonStopLoading_bound = this.ctrCompleteButtonStopLoading.bind(
    this
  );
  constructor(props) {
    super(props);

    const {
      dataKey,
      selectedForm: { template },
      templates,
      options: { isContributorSection = false } = {},
    } = props;
    this.state = {
      sectionCompleteLoading: false,
    };

    if (isContributorSection) {
      const properties = get(
        templates,
        [template, 'formSchema', 'form', 'properties'],
        []
      );
      const sectionWrapper = componentsManager.findContainersByKey(
        properties,
        dataKey
      );
      const fields = componentsManager.findFieldsByType(
        sectionWrapper,
        FIELD,
        CHECKBOX
      );
      const checkbox = fields.find(
        f => f.reportingKey === CONTRIBUTOR_SECTION_COMPLETE
      );
      // save section complete checkbox key to use later
      this.completeSectionCheckboxKey = checkbox.key;

      // remove the injected contributor section
      const sections = sectionWrapper[0].properties.filter(
        s => s.reportingKey !== CONTRIBUTOR_ASSIGNMENT_SECTION
      );
      const { fieldsByKey = {} } = getAllFieldsFlat(sections);
      this.fieldsByKey = fieldsByKey;
      // save fields keys to trigger validation later
      this.fieldKeys = Object.keys(fieldsByKey);
    }
  }

  componentWillMount() {
    this.createModal();
    eventEmitter.on(
      EventTypes.CTR_COMPLETE_BUTTON_STOP_LOADING,
      this.ctrCompleteButtonStopLoading_bound
    );
  }

  componentDidMount() {
    this.getSectionCompleteState();
  }

  componentWillUnmount() {
    this.hideModal();
    this.deleteModal();
    eventEmitter.off(
      EventTypes.CTR_COMPLETE_BUTTON_STOP_LOADING,
      this.ctrCompleteButtonStopLoading_bound
    );
  }

  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }

  ctrCompleteButtonStopLoading() {
    this.setState({ sectionCompleteLoading: false });
  }

  getSectionCompleteState = () => {
    const {
      id,
      parentKey,
      parentIndex,
      selectedForm: { data },
      options: { isContributorSection = false } = {},
    } = this.props;
    if (isContributorSection) {
      let section;
      if (parentKey) {
        const repeater = data[parentKey][parentIndex];
        section = data.__assignedSections.find(
          section => section.repeaterItemId === repeater.id
        );
      } else {
        section = data.__assignedSections.find(
          section => section.sectionId === id
        );
      }
      this.setState({ sectionComplete: section?.complete });
    }
  };

  showErrorConfirmation = (
    message = '',
    type = 'error',
    typeIcon = 'exclamation-circle'
  ) => {
    const ErrorTitle = (
      <ModalTitle type={type}>
        <Icon type={typeIcon} /> <span>Heads up!</span>
      </ModalTitle>
    );

    const ErrorText = getErrorMessageModalBody(message);

    this.updateModal({
      visible: true,
      title: ErrorTitle,
      children: ErrorText,
    });

    this.showModal();
  };

  updateCheckboxValue = () => {
    const { parentKey, parentIndex, dispatch } = this.props;
    const { sectionComplete } = this.state;
    const value = !sectionComplete;
    const keyPath = parentKey ? [parentKey, parentIndex] : [];
    keyPath.push(this.completeSectionCheckboxKey);

    this.setState({
      sectionComplete: value,
    });

    const dataValue = set({}, keyPath, value);
    dispatch(syncFormData(dataValue));
  };

  validateFieldsCallback = errors => {
    if (isEmpty(errors)) {
      const {
        parentKey,
        parentIndex,
        id,
        selectedForm: { data },
      } = this.props;
      const sectionId = parentKey
        ? get(data, [parentKey, parentIndex, 'id'])
        : id;
      eventEmitter.emit(EventTypes.CTR_ATTEMPT_SECTION_COMPLETE_OR_INCOMPLETE, {
        sectionId,
        isRepeater: !!parentKey,
      });
      this.updateCheckboxValue();
    } else {
      const errorMessages = getErrors(errors, this.fieldsByKey);

      this.showErrorConfirmation(errorMessages);
      this.setState({ sectionCompleteLoading: false });
    }
  };

  onButtonClick = event => {
    const { sectionComplete } = this.state;
    this.setState({ sectionCompleteLoading: true });

    if (sectionComplete) {
      // we only need to mark incomplete, no need to validate
      this.validateFieldsCallback();
    } else {
      // here we trigger the validation
      validateSectionWrapperFields(
        this.props,
        this.fieldKeys,
        this.fieldsByKey,
        this.validateFieldsCallback
      );
    }
  };

  render() {
    const props = this.props;

    const {
      form = {},
      isFirst = false,
      isLast = false,
      options = {},
      parentIndex = 0,
      parentKey = '',
      properties = [],
      ...rest
    } = props;
    const total = properties.length;
    const { className = '' } = options;
    const classes = classNames(className, {
      'bdm-section-wrapper-first': isFirst,
      'bdm-section-wrapper-last': isLast,
      'bdm-section-wrapper-not-first': !isFirst,
      'bdm-section-wrapper-not-last': !isLast,
      'bdm-edit-mode': true,
    });

    const { selectedForm, id: contributorSectionId } = this.props;
    // view hierarchy
    const {
      isContributorAssign,
      contributorAssignmentCanEdit,
      contributorAssignmentCanView,
      contributorAssignmentHideSection,
      contributorAssignmentSource,
      showCompleteIncompleteButton,
      contributorAssignmentIsComplete,
      contributorAssignmentIsAssigned,
    } = getContributeToReportVisibilityLevels(this.props);

    return (
      <SectionWrapperDiv
        className={classes}
        style={
          contributorAssignmentHideSection ? { display: 'none' } : undefined
        }
      >
        {properties.map(
          mapProperties({
            ...omit(rest, ['conditions', 'options']),
            form,
            parentKey,
            parentIndex,
            total,
            title: null,
            isContributeReport: selectedForm.isContributeReport,
            isContributorAssign,
            contributorAssignmentCanEdit,
            contributorAssignmentCanView,
            contributorAssignmentSource,
            contributorAssignmentIsComplete,
            contributorAssignmentIsAssigned,
            contributorSectionId,
          })
        )}
        {showCompleteIncompleteButton && (
          <Button
            type="primary"
            onClick={this.onButtonClick}
            loading={this.state.sectionCompleteLoading}
          >
            {contributorAssignmentIsComplete
              ? 'Mark incomplete'
              : 'Mark complete'}
          </Button>
        )}
      </SectionWrapperDiv>
    );
  }
}

const mapState = state => {
  const {
    form: {
      selectedForm,
      selectedForm: { data: { __assignedSections = [] } = {} } = {},
    } = {},
  } = state;
  return {
    __assignedSections,
    selectedForm,
  };
};

export default connect(mapState, null)(SectionWrapper);
