import {
  SELECT,
  MULTISELECT,
  MASKED,
  AUTOCOMPLETE,
  NUMBER,
} from 'APP_ROOT/constants/fieldTypes';

import autocompleteField from '../../utils/getCustomFields/getAutocompleteField';
import maskedField from '../../utils/getCustomFields/getMaskedField';
import {
  getAutocompleteOptions,
  setAutocompleteOptions,
} from '../../components/FieldEditor/utils';

const getComponentOptions = (field, key, updateEnum) => {
  let enumRef;
  let fieldOptions;
  let fieldValidations;
  switch (field.field_type) {
    case NUMBER:
      fieldOptions = {
        defaultValue: undefined,
      };
      break;
    case SELECT:
    case MULTISELECT:
      enumRef = key;
      updateEnum(enumRef, undefined, [{ label: 'Option', value: 'Value' }]);
      break;
    case AUTOCOMPLETE: {
      enumRef = key;
      const autoOptions = getAutocompleteOptions({});
      const autocomplete = new autocompleteField();
      const { name } = autocomplete;
      const value = {
        [name]: autocomplete.getInitialValue(field),
      };
      const { options } = autocomplete.onSave(value);
      fieldOptions = {
        ...options,
        ...setAutocompleteOptions(autoOptions),
      };
      break;
    }
    case MASKED: {
      const masked = new maskedField();
      const { name } = masked;
      const value = {
        [name]: masked.getInitialValue(field),
      };
      ({ options: fieldOptions, validations: fieldValidations } = masked.onSave(
        value
      ));
      break;
    }
    default:
      break;
  }

  return [fieldOptions, enumRef, fieldValidations];
};

export default getComponentOptions;
