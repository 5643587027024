import { getEnvironmentVariable } from 'APP_ROOT/appVersion';
import { hasPermissions, PERMISSIONS } from 'APP_ROOT/utils/admin';

const COLLAB_FLAG = 'REACT_APP_FEATURE_FLAG_CASEFILE_COLLAB';
export default props => {
  const { isOwner, editable, id, permissions } = props;
  const FEATURE_FLAG_CASEFILE_COLLAB = getEnvironmentVariable(
    COLLAB_FLAG,
    true
  );
  const manageAllCasefiles = hasPermissions(
    permissions,
    PERMISSIONS.manageAllCasefiles
  );
  const manageMyCasefiles = hasPermissions(
    permissions,
    PERMISSIONS.manageMyCasefiles
  );
  const isValidOwner = isOwner && manageMyCasefiles;

  return (
    FEATURE_FLAG_CASEFILE_COLLAB &&
    (isValidOwner || manageAllCasefiles) &&
    editable &&
    !!id
  );
};
