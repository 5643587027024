import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { get, isEmpty } from 'lodash';
import { Icon, Spin, Button } from 'antd';
import { unescape as htmlUnescape } from 'html-escaper';

import DashboardPage from 'APP_COMPONENTS/dashboard';
import PageHeader from 'APP_COMPONENTS/PageHeader';
import withoutClutter from 'APP_COMPONENTS/without-clutter';
import { getCurrentUser, getAgencyTZ } from 'APP_ROOT/selectors/session';
import { getFormValues } from 'APP_ROOT/containers/administrator/agency-users/AgencyUsers.selectors';
import getRoute from 'APP_ROOT/utils/get-route';

import caseFileEndpoints from '../../../api/caseFileEndpoints/caseFileEndpoints';
import PredefinedTasks from './components/predefinedTasks/PredefinedTasks';
import Reasons from './components/reasons/Reasons';
import Outcomes from './components/outcomes/Outcomes';
import CaseFileTypeDeadline from './components/caseFileTypeDeadline/CaseFileTypeDeadline';
import Statuses from './components/statuses/Statuses';
import { FORM_NAME, NOTIFICATION_ERROR, showNotification } from './constants';

import AdministratorWrapper from '../Administrator.styled';
import { hasFeatures, FEATURES } from '../../../utils/features';
import { LoaderContainer } from './AgencyCasefiles.styled';
import CaseFileTypeModal from './components/caseFileTypeForm/CaseFileTypeModal';

const AgencyCasefileType = React.forwardRef(
  (
    {
      casefileTypeId,
      casefileTypeData,
      timezone,
      agencyId,
      tenantId,
      ...props
    },
    ref
  ) => {
    const [casefileType, setCasefileType] = useState(null);
    const [showKeyDetails, setShowKeyDetails] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);

    const handleToggleModal = visible => setModalVisible(visible);

    useEffect(() => {
      const { user: { featureFlags } = {}, history } = props;

      const enableCasefileKeyDetails = hasFeatures(
        featureFlags,
        FEATURES.enableCasefileKeyDetails
      );

      setShowKeyDetails(enableCasefileKeyDetails);

      if (isEmpty(casefileTypeData)) {
        caseFileEndpoints
          .getCasefileTypes(tenantId)
          .then(response => {
            if (response?.content.length > 0) {
              const _casefileType = response.content.find(
                type => type.id === casefileTypeId
              );

              if (!_casefileType) {
                throw new Error('Case File Type not found');
              }

              setCasefileType({
                key: _casefileType.id,
                type: htmlUnescape(_casefileType.name),
                category: _casefileType.categoryName,
                categoryId: _casefileType.categoryId,
                abbreviation: _casefileType.abbreviation,
                createdAt: _casefileType.createdAt,
                deadline: _casefileType.deadline,
                allowsDeletion: _casefileType.allowsDeletion,
              });
            }
          })
          .catch(err => {
            const message = err.message || 'API response is not ok';
            showNotification(NOTIFICATION_ERROR, 'Error', message);
            history.push(
              getRoute('administratorAgencyCasefiles', {
                agencyId,
                tenantId,
              })
            );
          });
      } else {
        const _casefileType = casefileTypeData?.values?.casefileType;
        setCasefileType(_casefileType);
      }
    }, []);

    return (
      <AdministratorWrapper>
        <PageHeader
          showTitle={casefileType}
          isLoading={!casefileType}
          title={
            casefileType ? (
              <>
                {casefileType.type}{' '}
                <Icon type="setting" onClick={() => handleToggleModal(true)} />{' '}
              </>
            ) : (
              ''
            )
          }
          goBackTo={getRoute('administratorAgencyCasefiles', {
            agencyId,
            tenantId,
          })}
        />
        {casefileType ? (
          <>
            {showKeyDetails && (
              <CaseFileTypeDeadline casefileType={casefileType} />
            )}
            <PredefinedTasks timezone={timezone} casefileType={casefileType} />
            {showKeyDetails && (
              <Reasons timezone={timezone} casefileType={casefileType} />
            )}
            {showKeyDetails && (
              <Outcomes timezone={timezone} casefileType={casefileType} />
            )}
            {showKeyDetails && (
              <Statuses timezone={timezone} casefileType={casefileType} />
            )}
          </>
        ) : (
          <LoaderContainer>
            <Spin
              indicator={<Icon type="loading" style={{ fontSize: 48 }} spin />}
            />
            <span>Loading...</span>
          </LoaderContainer>
        )}
        <CaseFileTypeModal
          timezone={timezone}
          isCreatingCaseFile={false}
          casefileType={casefileType}
          isVisible={modalVisible}
          handleToggleModal={handleToggleModal}
          afterDeleteAction={() => {
            props.history.push(
              getRoute('administratorAgencyCasefiles', {
                agencyId,
                tenantId,
              })
            );
          }}
        />
      </AdministratorWrapper>
    );
  }
);

const mapState = (state, props) => {
  const user = getCurrentUser(state);
  const agencyId = get(props, 'match.params.agencyId');
  const tenantId = get(props, 'match.params.tenantId');
  const casefileTypeId = get(props, 'match.params.casefileTypeId');
  const casefileTypeData = getFormValues(state, FORM_NAME);
  const timezone = getAgencyTZ(state);
  return {
    user,
    agencyId,
    tenantId,
    timezone,
    casefileTypeId,
    casefileTypeData,
  };
};

export default withoutClutter(
  DashboardPage(mapState)(withRouter(AgencyCasefileType))
);
