import { isEmpty } from 'lodash';

const getOnBehalfUsers = report => {
  const { onBehalf } = report;
  const hasUsersOnBehalf = !isEmpty(onBehalf);
  let usersOnBehalf = [];

  if (hasUsersOnBehalf) {
    JSON.stringify(onBehalf, function(key, value) {
      usersOnBehalf = Object.values(value);
    });
  }
  return usersOnBehalf;
};

export default getOnBehalfUsers;
