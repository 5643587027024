import React, { PureComponent } from 'react';
import { Row, Col, Tooltip } from 'antd';
import ContentEditable from 'react-contenteditable';
import PropTypes from 'prop-types';
import {
  last,
  get,
  isEmpty,
  has,
  kebabCase,
  find,
  castArray,
  template,
  set,
  cloneDeep,
} from 'lodash';
import cx from 'classnames';
import { connect } from 'react-redux';

// Components
import IconButton from '../common/buttons/icon-button';
import LoadingReport from './LoadingReport';
import StatusLabel from './StatusLabel';
import ParagraphContainer from './ParagraphContainer.styled';
import {
  StyledPanel,
  StyledDivider,
  StyledPanelTitle,
} from './ReportCard.styled';
import ReportMeta from './ReportMeta';

// Utilities
import getLastStatus from '../../utils/get-report-status';
import { getAllegationTypeList, getFindingsTypeList } from '../../utils/form';
import getDataFromNotes from '../../utils/get-data-from-notes';
import getLinkedFormTemplate from '../../utils/get-linked-form-template';
import FORM_TYPES from '../../constants/form-types';
import parseDate, {
  BENCHMARK_DATE_FORMAT,
  BENCHMARK_DATE_TIMEZ_FORMAT,
} from '../../utils/parse-date';

import getOnBehalfUsers from '../utils/getOnBehalfUsers';
import getReportUsers from '../utils/getReportUsers';

import deleteConfirmationModalOptions from '../../containers/report-type/report-container/deleteConfirmationModalOptions';
import createModal from 'APP_ROOT/actions/create-modal';
import showModal from 'APP_ROOT/actions/show-modal';
import hideModal from 'APP_ROOT/actions/hide-modal';
import deleteReportSoftDelete from 'APP_ROOT/actions/delete-report-softdelete';
import { getAgencyTZ } from 'APP_ROOT/selectors/session';

import ModalTitle from '../../components/common/modal/title';
import ModalBody from '../../components/common/modal/body';
import FormHistoryTimeline from '../../components/form-history-timeline';

import transformFormToNormalizedTimeline from '../../utils/transform-form-to-normalized-timeline';

import { getTemplateConfiguration } from '../../selectors/session';
import { getCurrentUserPermissions } from '../../selectors/session';
const DELETE_REPORT_CONFIRM_MODAL_ID = 'report-delete-request';
import retrieveReport from '../../../src/actions/utils/edit-form/retrieveReport';

import isMobileDevice from '../../utils/isMobileDevice';

const isMobile = isMobileDevice();

const REPORT_FILTER_PARTICIPANTS = {
  relation: 'participants',
  scope: {
    fields: [
      'fullName',
      'lastName',
      'firstName',
      'starNumber',
      'email',
      'rankId',
    ],
    include: [
      {
        relation: 'rank',
        scope: {
          fields: { name: true, abbreviation: true, id: true },
        },
      },
    ],
  },
};
const REPORT_FILTER_NOTES = {
  relation: 'notes',
  scope: {
    include: ['historyStep'],
    order: 'created ASC',
  },
};
const REPORT_FILTER_ACTIONS = {
  relation: 'actions',
  scope: {
    order: 'created ASC',
  },
};
const REPORT_FILTER_LINKED_FORMS = {
  relation: 'linkedForms',
  scope: {
    include: {
      relation: 'actions',
    },
  },
};
const REPORT_FILTER_SUBMITTED_FORMS = {
  relation: 'submittedForms',
  scope: {
    include: {
      relation: 'actions',
      scope: {
        where: { status: 'pending' },
      },
    },
  },
};
const REPORT_FILTER_FORM_LINKS = {
  relation: 'formLinks',
  scope: {
    include: ['start', 'end'],
  },
};
const REPORT_FILTER_LINKED_SUBMISSIONS = {
  relation: 'linkedSubmissions',
  scope: {
    include: {
      relation: 'actions',
      scope: {
        where: { status: 'pending' },
      },
    },
  },
};
class ReportCard extends PureComponent {
  getFilter() {
    return [
      REPORT_FILTER_PARTICIPANTS,
      REPORT_FILTER_NOTES,
      REPORT_FILTER_ACTIONS,
      REPORT_FILTER_LINKED_FORMS,
      REPORT_FILTER_SUBMITTED_FORMS,
      REPORT_FILTER_FORM_LINKS,
      REPORT_FILTER_LINKED_SUBMISSIONS,
    ];
  }

  displayReportCardDetails = () => {
    const { templateConfiguration, report, timezone } = this.props;
    const currentState = get(report, 'workFlowData.state');
    const displayConfigurations = get(
      templateConfiguration,
      'reportsCard.value'
    );
    const displayConfiguration = find(displayConfigurations, item =>
      castArray(item.states).includes(currentState)
    );
    const configurationContext = get(displayConfiguration, 'context');
    const dateFields = get(configurationContext, 'dates', []);

    const users = getReportUsers();
    const context = cloneDeep(
      Object.assign({}, report, {
        users,
      })
    );

    try {
      dateFields.forEach(propName => {
        const currentValue = get(context, propName);

        if (currentValue) {
          const formattedValue = parseDate(new Date(currentValue), timezone);

          set(context, propName, formattedValue);
        }
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log('error', error);
    }

    const messages = get(displayConfiguration, 'message', []);
    const interpolatedMessages = messages.map(({ value: message }) => {
      const interpolateMessage = template(message);
      return interpolateMessage(context);
    });

    return (
      <ParagraphContainer>
        {interpolatedMessages.map((message, idx) => (
          <p key={idx}>{message}</p>
        ))}
      </ParagraphContainer>
    );
  };

  getReportRoute = (id, agencyId) => {
    return agencyId
      ? `/administrator/agencies/${agencyId}/reports/${id}`
      : `/reports/${id}`;
  };

  onCancelDeleteConfirmationModalClick = () => {
    const { dispatch } = this.props;
    dispatch(hideModal(DELETE_REPORT_CONFIRM_MODAL_ID));
  };

  onAcceptConfirmationModalClick = () => {
    const { dispatch, report } = this.props;
    const { id } = report;

    dispatch(
      deleteReportSoftDelete(id, () => {
        dispatch(hideModal(DELETE_REPORT_CONFIRM_MODAL_ID));
      })
    );
  };

  deleteReport = () => {
    const { dispatch } = this.props;
    const options = {
      id: DELETE_REPORT_CONFIRM_MODAL_ID,
      title: deleteConfirmationModalOptions.title,
      children: deleteConfirmationModalOptions.text,
      footer: deleteConfirmationModalOptions.buildFooter(
        this.onCancelDeleteConfirmationModalClick,
        this.onAcceptConfirmationModalClick
      ),
    };
    dispatch(createModal(options));
    dispatch(showModal(options.id));
  };

  redirectToReportDetail = () => {
    const { report, agencyId } = this.props;
    return this.getReportRoute(report.id, agencyId);
  };

  getLastReviewerDate = () => {
    const {
      report: { transactionHistory: history = [] },
    } = this.props;
    const lastReviewHistory = history.length
      ? history.filter(
          data => data.action !== 'initial.submit' && data.type === 'workflow'
        )
      : [];
    const lastReview = lastReviewHistory.length ? last(lastReviewHistory) : {};

    const { date = '' } = lastReview;
    return date;
  };

  getSubmittedBy = () => {
    const { report } = this.props;
    const { submitterId, participants = [] } = report;
    // Adding a fallback user data when there isn't a participant match
    // NJ hookup scenario, could be not needed later
    const fallbackSubmittedBy = {
      firstName: 'Not Available',
      lastName: '',
    };

    return participants.reduce((result, item) => {
      if (parseInt(item.id) === submitterId) {
        return {
          firstName: item.firstName,
          lastName: item.lastName,
        };
      }
      return {
        ...result,
      };
    }, fallbackSubmittedBy);
  };

  getFormData = statusKey => {
    const { report } = this.props;
    const { data = {}, actions } = report;
    return statusKey === 'pending'
      ? get(
          actions.filter(action => action.status === 'pending'),
          [0, 'data'],
          data
        )
      : data;
  };

  getCustomMeta = (participants, usersOnBehalf, report) => {
    const {
      meta = {},
      id,
      formType,
      formNumber,
      transactionHistory = [],
      participantsId = [],
      linkedSubmissions = [],
      notes = [],
      number,
      agencyId,
      data: { __assignedSectionsHistory = [] },
    } = report;
    return {
      ...meta,
      id,
      formType,
      formNumber,
      transactionHistory,
      participantsId,
      linkedSubmissions,
      notes,
      number,
      agencyId,
      participants,
      usersOnBehalf,
      __assignedSectionsHistory,
    };
  };

  getTemplateName = () => {
    const { report, templates } = this.props;
    const { formType, templateName } = report;
    return templateName || get(templates, `${formType}.name`, formType);
  };

  viewHistory = e => {
    e.stopPropagation();

    const { report, timezone, dispatch } = this.props;
    const { formNumber } = report;
    const templateName = this.getTemplateName();

    const Title = (
      <ModalTitle>
        <span
          dangerouslySetInnerHTML={{
            __html: `${templateName} ${formNumber} - Timeline`,
          }}
        />
      </ModalTitle>
    );

    retrieveReport({
      id: report.id,
      agencyId: report.agencyId,
      filter: this.getFilter(),
    }).then(result => {
      const report = result[1];
      const usersOnBehalf = getOnBehalfUsers(report);
      const users = getReportUsers(report);
      const snapshotUsers = Object.values(users);
      const metaCustom = this.getCustomMeta(
        snapshotUsers,
        usersOnBehalf,
        report
      );
      const normalizedRecord = transformFormToNormalizedTimeline(
        metaCustom,
        timezone
      );

      const recordWithReport = { ...normalizedRecord, report };

      const Body = (
        <ModalBody>
          <FormHistoryTimeline {...recordWithReport} />
        </ModalBody>
      );
      const modalOptions = {
        id: 'timeline-modal',
        visible: true,
        title: Title,
        children: Body,
      };
      dispatch(createModal(modalOptions));
      dispatch(showModal(modalOptions.id));
    });
  };

  renderTimelineButton = () => {
    const {
      report: { transactionHistory: history = [] },
    } = this.props;
    const historyIcon = require('APP_ROOT/icons/ic_history.svg');
    const TIMELINE = 'Timeline';

    return history.length ? (
      <Tooltip title={TIMELINE}>
        <IconButton
          key="timeline"
          shape="circle"
          onClick={this.viewHistory}
          className="timeline"
          style={{ marginTop: '-2px', marginLeft: '12px' }}
        >
          <img src={historyIcon} alt="Timeline" />
        </IconButton>
      </Tooltip>
    ) : (
      <></>
    );
  };

  submittedByLabel = () => {
    const { report, timezone } = this.props;
    const {
      onBehalf = {},
      organizationalUnitName,
      organizationalUnitDisplayName,
      submissionDate,
    } = report;
    const hasUsersOnBehalf = !isEmpty(onBehalf);
    const usersOnBehalfFormatted = getOnBehalfUsers(report);
    const dateFormat = isMobile
      ? BENCHMARK_DATE_FORMAT
      : BENCHMARK_DATE_TIMEZ_FORMAT;
    const reportSubmissionDate = submissionDate
      ? parseDate(submissionDate, timezone, dateFormat)
      : submissionDate;
    const submittedBy = this.getSubmittedBy();

    const completeLabel = [
      `Submitted by ${submittedBy.firstName} ${submittedBy.lastName}`,
    ];
    if (hasUsersOnBehalf) {
      completeLabel.push(
        `on behalf of ${usersOnBehalfFormatted[0].firstName} ${usersOnBehalfFormatted[0].lastName}`
      );
    }

    const ouDisplayName = this.getDisplayName(
      organizationalUnitDisplayName,
      organizationalUnitName
    );
    if (!isEmpty(ouDisplayName) && !isMobile) {
      completeLabel.push(`from ${ouDisplayName}`);
    }

    completeLabel.push(`at ${reportSubmissionDate}`);
    return (
      reportSubmissionDate && (
        <ContentEditable
          className="card-line"
          html={completeLabel.join(' ')}
          disabled={true}
        />
      )
    );
  };

  getDisplayName = (displayName, name) => {
    return !isEmpty(displayName) ? displayName : !isEmpty(name) ? name : '';
  };

  renderEventDate = () => {
    const { report, timezone } = this.props;
    const { eventDate } = report;
    const hasEventDate = !isEmpty(eventDate);

    return (
      hasEventDate && (
        <div className="event-date">
          Event Date: {parseDate(eventDate, timezone, BENCHMARK_DATE_FORMAT)}
        </div>
      )
    );
  };

  onBehalfLabel = () => {
    const { report, timezone } = this.props;
    const { onBehalf = {}, lastDraftAt } = report;
    const hasUsersOnBehalf = !isEmpty(onBehalf);
    const usersOnBehalfFormatted = getOnBehalfUsers(this.props.report);
    const formattedDraftDate = parseDate(lastDraftAt, timezone);
    const label = hasUsersOnBehalf
      ? `Last updated at ${formattedDraftDate}${' '}
  on behalf of ${usersOnBehalfFormatted[0].firstName} ${
          usersOnBehalfFormatted[0].lastName
        }`
      : `Last updated at ${formattedDraftDate}`;

    return (
      <StatusLabel
        className={null}
        stateLabel={
          <ContentEditable className="card-line" html={label} disabled={true} />
        }
      />
    );
  };

  isIAC = () => {
    const { report } = this.props;
    const { formType } = report;
    return kebabCase(formType) === FORM_TYPES.intake;
  };

  isIAI = () => {
    const { report } = this.props;
    const { formType } = report;
    return kebabCase(formType) === FORM_TYPES.investigation;
  };

  isDraft = () => {
    const { currentUser, report } = this.props;
    const lastStatus = getLastStatus(report, currentUser);
    const { key: statusKey = '' } = lastStatus;
    const { workFlowData = {} } = report;
    const workFlowDataAsInitial = get(workFlowData, 'state') === 'initial';

    return statusKey === 'draft' || workFlowDataAsInitial;
  };

  hasReviews = isAdministravelyUnfounded => {
    const { timezone } = this.props;
    const lastReviewerDate = this.getLastReviewerDate();
    const reportLastReviewDate = lastReviewerDate
      ? parseDate(lastReviewerDate, timezone, BENCHMARK_DATE_TIMEZ_FORMAT)
      : lastReviewerDate;
    return !isAdministravelyUnfounded && !!reportLastReviewDate;
  };

  isCompleted = (isAdministravelyUnfounded, getCurrentTransactionLabel) => {
    const { currentUser, report } = this.props;
    const { isPaused } = report;
    const lastStatus = getLastStatus(report, currentUser);
    const { key: statusKey = '' } = lastStatus;

    return (
      statusKey === 'completed' &&
      !isPaused &&
      isAdministravelyUnfounded &&
      !!getCurrentTransactionLabel
    );
  };

  hasLabel = (isIAC, isAdministravelyUnfounded, getCurrentTransactionLabel) => {
    const { report } = this.props;
    const { workFlowData = {}, isPaused } = report;
    const { stateLabel = '' } = workFlowData;
    if (stateLabel) return false;

    const isIntakePaused = isPaused && isIAC;
    if (isIntakePaused) return false;

    const hasReviews = this.hasReviews(isAdministravelyUnfounded);
    if (hasReviews) return false;

    const isCompleted = this.isCompleted(
      isAdministravelyUnfounded,
      getCurrentTransactionLabel
    );

    return !isCompleted;
  };

  hasReportCardConfiguration = () => {
    const { templateConfiguration } = this.props;
    return has(templateConfiguration, 'reportsCard');
  };

  getLastHistory = () => {
    const { report } = this.props;
    return last(
      get(report, 'transactionHistory', []).filter(
        step => step.type === 'workflow'
      )
    );
  };

  isAdministravelyUnfounded = getLastHistory => {
    const getCurrentTransactionState = get(getLastHistory, 'toState');
    return getCurrentTransactionState === 'administratively unfounded';
  };

  findingsTypeList = isIAI => {
    const { report, officers } = this.props;

    const { notes = [] } = report;
    const dataFromNotes = getDataFromNotes(notes, [
      'notConcur',
      'concurWithFindings',
    ]);

    // Get Findings list
    const findings = isIAI
      ? isEmpty(dataFromNotes)
        ? get(report, 'data.overallFindings', [])
        : get(dataFromNotes, 'reviewOverallFindings', [])
      : [];
    return findings.length ? getFindingsTypeList(findings, officers) : '';
  };

  renderLinkedForms = isIAC => {
    const { report, templates, agencyId } = this.props;
    const linkedForms = [
      ...(report.linkedForms || []),
      ...(report.submittedForms || []),
    ]; // checking is needed because these fields are not returned when form is submitted

    return (
      linkedForms.length > 0 &&
      !isIAC && (
        <ParagraphContainer>
          {linkedForms.map(linkedForm => (
            <div className="linked-form-details" key={linkedForm.id}>
              {getLinkedFormTemplate({
                report,
                linkedForm,
                templates,
                formLinkRef: this.getReportRoute(report.id, agencyId),
                linkedFormLinkRef: this.getReportRoute(linkedForm.id, agencyId),
              })}
            </div>
          ))}
        </ParagraphContainer>
      )
    );
  };

  renderIncidentNumber = isIAI => {
    const { report } = this.props;
    const hasIncidentNumber = has(get(report, 'data', {}), 'incidentNumber');

    const incidentNumber = get(report, 'data.incidentNumber', '');

    return (
      isIAI &&
      hasIncidentNumber && (
        <p className="incident-number">Incident Number: {incidentNumber}</p>
      )
    );
  };

  renderAllegationTypeList = (formData, isIAI) => {
    const { report } = this.props;
    const { notes = [] } = report;
    const dataFromNotes = getDataFromNotes(notes, [
      'notConcur',
      'concurWithFindings',
    ]);

    // Get Allegations Type(s) list
    const allegationTypeList = getAllegationTypeList(
      {
        ...formData,
        ...dataFromNotes,
      },
      isIAI
        ? isEmpty(dataFromNotes)
          ? 'findings'
          : 'reviewFindings'
        : 'allegations',
      'allegationType'
    );

    return (
      allegationTypeList && (
        <p className="card-line">Allegation Type(s): {allegationTypeList}</p>
      )
    );
  };

  renderFindingsTypeList = (isIAI, statusKey) => {
    const findingsTypeList = this.findingsTypeList(isIAI);

    return (
      isIAI &&
      statusKey === 'completed' &&
      findingsTypeList && (
        <p className="findings card-line">Findings: {findingsTypeList}</p>
      )
    );
  };

  renderMissingReportConfiguration = ({
    isIAC,
    isIAI,
    isDraft,
    isAdministravelyUnfounded,
    getCurrentTransactionLabel,
  }) => {
    const { currentUser, report, timezone } = this.props;
    const { workFlowData = {}, isPaused } = report;
    const { stateLabel = '' } = workFlowData;
    const lastStatus = getLastStatus(report, currentUser);
    const { key: statusKey = '' } = lastStatus;
    const lastReviewerDate = this.getLastReviewerDate();
    const reportLastReviewDate = lastReviewerDate
      ? parseDate(lastReviewerDate, timezone, BENCHMARK_DATE_TIMEZ_FORMAT)
      : lastReviewerDate;
    const formData = this.getFormData(statusKey);
    const hasReportCardConfiguration = this.hasReportCardConfiguration();

    return (
      <div>
        {isDraft ? (
          <ParagraphContainer>
            {!hasReportCardConfiguration && this.onBehalfLabel()}
            {this.renderEventDate()}
          </ParagraphContainer>
        ) : (
          <ParagraphContainer>
            <Row>
              <Col xs={24} sm={18}>
                {this.submittedByLabel()}
                {this.renderEventDate()}
                {this.renderIncidentNumber(isIAI)}
                {this.renderAllegationTypeList(formData, isIAI)}
                {this.renderFindingsTypeList(isIAI, statusKey)}
              </Col>
              {!isMobile && (
                <Col xs={24} sm={6} style={{ textAlign: 'right' }}>
                  <StatusLabel
                    isPaused={isPaused}
                    isIntake={isIAC}
                    isInvestigation={isIAI}
                    statusKey={statusKey}
                    isAdministravelyUnfounded={isAdministravelyUnfounded}
                    transactionLabel={getCurrentTransactionLabel}
                    lastReviewDate={reportLastReviewDate}
                    stateLabel={stateLabel}
                  />
                </Col>
              )}
            </Row>
          </ParagraphContainer>
        )}
        {this.renderLinkedForms(isIAC)}
      </div>
    );
  };

  renderStyledPanelTitle = () => {
    const { report } = this.props;
    const templateName = this.getTemplateName();
    const { caseNumber = [] } = report;
    const hasCaseNumber = caseNumber.length > 0;

    return (
      <StyledPanelTitle>
        <h2
          dangerouslySetInnerHTML={{
            __html: `${templateName} ${report.formNumber}`,
          }}
        />
        {hasCaseNumber && (
          <span className="case-number">
            <strong>Case Number:</strong>{' '}
            <span
              dangerouslySetInnerHTML={{
                __html: caseNumber.join(', '),
              }}
            />
          </span>
        )}
      </StyledPanelTitle>
    );
  };

  renderStylePanelTopRow = (isDraft, lastStatus, currentUser) => {
    const { report } = this.props;
    const { workFlowData = {} } = report;
    const { status: workflowStatus = {} } = workFlowData;

    const deleteIcon = require('APP_ROOT/icons/bt_delete.svg');
    // delete report is not allowed here by CV-417,
    // commented out this code in case we need to revert
    // const { currentUserPermissions } = this.props;
    // const isSoftDeleteAllow = hasPermissions(currentUserPermissions, [
    //   PERMISSIONS.deleteReport,
    // ]);
    const isSoftDeleteAllow = false;
    const DELETEREPORT = 'Delete Report';

    return (
      <Row align={'top'}>
        <a href={this.redirectToReportDetail()}>
          <Col xs={20} sm={8}>
            {this.renderStyledPanelTitle()}
          </Col>
        </a>
        <Col xs={4} sm={4}>
          {!isDraft && isSoftDeleteAllow && (
            <Tooltip title={DELETEREPORT}>
              <IconButton shape="circle" onClick={this.deleteReport}>
                {<img src={deleteIcon} alt="Button" />}
              </IconButton>
            </Tooltip>
          )}
          {this.renderTimelineButton()}
        </Col>
        <a href={this.redirectToReportDetail()}>
          <Col xs={24} sm={12}>
            <Row type="flex" gutter={16} justify="end">
              <Col span={24}>
                <ReportMeta
                  report={report}
                  lastStatus={lastStatus}
                  workflowStatus={workflowStatus}
                  currentUser={currentUser}
                  showParticipants
                />
              </Col>
            </Row>
          </Col>
        </a>
      </Row>
    );
  };

  renderPanelBody = (isDraft, statusKey) => {
    const { report, timezone } = this.props;
    const { workFlowData = {}, isPaused } = report;
    const { stateLabel = '' } = workFlowData;
    const lastReviewerDate = this.getLastReviewerDate();
    const reportLastReviewDate = lastReviewerDate
      ? parseDate(lastReviewerDate, timezone, BENCHMARK_DATE_TIMEZ_FORMAT)
      : lastReviewerDate;
    const isIAC = this.isIAC();
    const isIAI = this.isIAI();
    const getLastHistory = this.getLastHistory();
    const getCurrentTransactionLabel = get(getLastHistory, 'actionLabel');
    const isAdministravelyUnfounded = this.isAdministravelyUnfounded(
      getLastHistory
    );
    const hasLabel = this.hasLabel(
      isIAC,
      isAdministravelyUnfounded,
      getCurrentTransactionLabel
    );
    //   !isIntakePaused && !stateLabel && !isCompleted & !hasReviews;
    const hasReportCardConfiguration = this.hasReportCardConfiguration();

    const panelBodyClassName = isMobile ? '' : cx({ 'panel-body': !hasLabel });
    return (
      <div className={panelBodyClassName}>
        {hasReportCardConfiguration && (
          <ParagraphContainer>
            {!isDraft && (
              <StatusLabel
                isPaused={isPaused}
                isIntake={isIAC}
                isInvestigation={isIAI}
                statusKey={statusKey}
                isAdministravelyUnfounded={isAdministravelyUnfounded}
                transactionLabel={getCurrentTransactionLabel}
                lastReviewDate={reportLastReviewDate}
                stateLabel={stateLabel}
              />
            )}
            {this.displayReportCardDetails()}
          </ParagraphContainer>
        )}
        {!hasReportCardConfiguration &&
          this.renderMissingReportConfiguration({
            isIAC,
            isIAI,
            isDraft,
            isAdministravelyUnfounded,
            getCurrentTransactionLabel,
          })}
      </div>
    );
  };

  render() {
    const {
      currentUser,
      report,
      history: routerHistory,
      timezone,
      templates,
      officers,
      loading = false,
      showSecondLoadingLine,
      templateConfiguration,
      templateType,
      agencyId,
      ...props
    } = this.props;

    if (loading) {
      return <LoadingReport showSecondLoadingLine={showSecondLoadingLine} />;
    }

    const lastStatus = getLastStatus(report, currentUser);
    const { key: statusKey = '' } = lastStatus;

    const isDraft = this.isDraft();

    return (
      <StyledPanel {...props}>
        {this.renderStylePanelTopRow(isDraft, lastStatus, currentUser)}
        <a href={this.redirectToReportDetail()}>
          <StyledDivider />
          {this.renderPanelBody(isDraft, statusKey)}
        </a>
      </StyledPanel>
    );
  }
}

ReportCard.propTypes = {
  report: PropTypes.object,
};

const mapState = (state, props) => {
  const templateType = get(props, 'report.formType', null);
  const templateConfiguration = getTemplateConfiguration(
    kebabCase(templateType)
  );
  const currentUserPermissions = getCurrentUserPermissions(state);
  const timezone = getAgencyTZ(state);

  return {
    templateConfiguration: templateConfiguration(state),
    templateType,
    currentUserPermissions,
    timezone,
  };
};

export default connect(mapState)(ReportCard);
