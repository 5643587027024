import React from 'react';
import { Icon } from 'antd';
import {
  sortAlphabetically,
  renderDate,
  sortByCreationDate,
} from '../../constants';

export default (timezone, handleClick) => [
  {
    title: 'Title',
    dataIndex: 'title',
    key: 'title',
    sorter: (a, b) => sortAlphabetically(a, b, 'title'),
  },
  {
    title: 'Created Date',
    dataIndex: 'createdAt',
    key: 'createdAt',
    width: '15%',
    defaultSortOrder: 'ascend',
    sorter: (a, b) => sortByCreationDate(a, b),
    render: data => renderDate(data, timezone),
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    width: '10%',
    render: (_, data) => (
      <Icon
        type="delete"
        style={{ color: 'red' }}
        onClick={() => handleClick(data)}
      />
    ),
  },
];
