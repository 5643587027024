import React, { Component } from 'react';
import { Row, Col, Icon, Tooltip } from 'antd';
import classNames from 'classnames';
import { isPlainObject, omit, get, has } from 'lodash';
import { connect } from 'react-redux';
import ContentEditable from 'react-contenteditable';
import Tag from '../../../common/tag';

import { getFormTemplate, getDataEnums } from 'APP_ROOT/selectors/form';
import { getFieldSelector } from 'APP_ROOT/utils/renderSchema';

import templateString from 'APP_ROOT/utils/template-string';
import { mapProperties } from 'APP_ROOT/utils/form';
import { mapTitle } from 'APP_ROOT/utils/conditionals';
import StyledSection from '../../styled/section';
import Title from '../../styled/section-title';

import propsHasChanged from 'APP_ROOT/utils/propsHasChanged';
import logChangedProps from 'APP_ROOT/utils/logChangedProps';

import getTitleTooltip from '../../../utils/getTitleTooltip';
import decorateTextWithHyperlink from '../../../utils/decorateTextWithHyperlink';
import { CONTRIBUTOR_ASSIGNMENT_SECTION } from '../../../../constants/contributeToReport.js';

import ContributorTimeline from './ContributorTimeline.js';
import getContributorSectionHistory from '../utils/getContributorSectionHistory.js';

let counter = 0;

export class SectionChildren extends Component {
  shouldComponentUpdate(nextProps) {
    return propsHasChanged(nextProps, this.props);
  }

  componentDidUpdate(prevProps) {
    const name =
      this.constructor.displayName || this.constructor.name || 'Component';
    logChangedProps(prevProps, this.props, name);
  }
  buildAssignmentSection(isContributorAssignedSection) {
    if (!isContributorAssignedSection) return;
    const {
      parentKey,
      parentIndex,
      contributorSectionId,
      contributorAssignmentCanView: canView,
      contributorAssignmentIsComplete: isComplete,
    } = this.props;
    const sectionHistory = canView && getContributorSectionHistory(this.props);
    const showTimeline = sectionHistory?.length > 0;
    return (
      <div className="contributor-assignment-title">
        <span style={{ flex: 1, marginBottom: '15px' }}>
          Contributor Assignment
        </span>
        {showTimeline && (
          <ContributorTimeline
            parentKey={parentKey}
            parentIndex={parentIndex}
            contributorSectionId={contributorSectionId}
          />
        )}
        <Tag type={isComplete ? 'closed' : 'take-action'} statusTag={true}>
          {isComplete ? 'Complete' : 'Incomplete'}
        </Tag>
      </div>
    );
  }

  render() {
    const {
      data = {},
      form = {},
      isFirst,
      isLast,
      options = {},
      parentIndex = 0,
      properties = [],
      overrides = {},
      nodeId = '',
      title: propTitle,
      titleIndex,
      isContributeReport,
      isContributorAssign,
      contributorAssignmentCanEdit,
      contributorAssignmentCanView,
      contributorAssignmentSource,
      contributorAssignmentIsComplete,
      contributorAssignmentIsAssigned,
      contributorSectionId,
    } = this.props;

    const total = properties.length;
    const {
      titleType = 'large',
      className = '',
      titleClassName = '',
      tooltipText,
    } = options;
    const isContributorAssignedSection =
      this.props.reportingKey === CONTRIBUTOR_ASSIGNMENT_SECTION;
    const classes = classNames(className.split(' '), {
      'bdm-form-section-first': isFirst,
      'bdm-form-section-not-first': !isFirst,
      'bdm-form-section-last': isLast,
      'bdm-form-section-not-last': !isLast,
      'bdm-form-section-contributor-assignment-section': isContributorAssignedSection,
    });

    const sectionOverrides = overrides[nodeId] || {};
    const title = sectionOverrides.title || propTitle;
    const interpolatedTitle = title
      ? isPlainObject(title)
        ? mapTitle(title, parentIndex, data)
        : templateString(title, { index: titleIndex + 1 })
      : '';
    const { tooltipTitle, tooltipSize = 'large', hasTooltip } = getTitleTooltip(
      this.props
    );
    const history = get(
      this.props,
      ['selectedForm', 'data', '__assignedSectionsHistory'],
      []
    );
    // we want to render for old reports, so not having id field in history
    const shouldRenderHeaderDate = !history.some(entry => has(entry, 'id'));

    counter++;
    return (
      <StyledSection className={classes} isLast={isLast} data-key={counter}>
        {title && (
          <Row>
            <Col className="gutter-row" span={24}>
              <Title size={titleType} className={titleClassName}>
                <ContentEditable
                  html={decorateTextWithHyperlink(interpolatedTitle)}
                  disabled={true}
                  className="section-title"
                />{' '}
                {(hasTooltip || tooltipText) && (
                  <Tooltip
                    placement="rightTop"
                    autoAdjustOverflow
                    overlayClassName={`info-tooltip info-tooltip-${tooltipSize}`}
                    title={tooltipText || tooltipTitle}
                  >
                    <Icon type="question-circle" />
                  </Tooltip>
                )}
              </Title>
            </Col>
          </Row>
        )}
        {this.buildAssignmentSection(isContributorAssignedSection)}
        {properties.map(
          mapProperties({
            ...omit(this.props, ['conditions', 'options', 'data', 'dataEnums']),
            form,
            parentIndex,
            total,
            title: null,
            isContributeReport,
            isContributorAssign,
            contributorAssignmentCanEdit,
            contributorAssignmentCanView,
            contributorAssignmentSource,
            contributorAssignmentIsComplete,
            contributorAssignmentIsAssigned,
            contributorSectionId,
            shouldRenderHeaderDate,
          })
        )}
      </StyledSection>
    );
  }
}

const mapState = (state, props) => {
  const dataSelector = getFieldSelector(props);
  const formTemplate = getFormTemplate(state, props);

  return {
    data: dataSelector(state, props),
    dataEnums: getDataEnums(state, props, formTemplate),
  };
};

export default connect(mapState)(SectionChildren);
