import { notification } from 'antd';

const KEY = 'CASE-FILES-COLUMNS-PREFERENCE';

const showNotification = (type, message, description) =>
  notification[type]({ message, description });

export default {
  getColumns: () => {
    try {
      const jsonString = localStorage.getItem(KEY);
      const columns = JSON.parse(jsonString);
      return columns;
    } catch (error) {
      showNotification(
        'error',
        'Something went wrong',
        'Error retrieving your column preferences'
      );
      return null;
    }
  },
  saveColumns: columns => {
    try {
      const jsonString = JSON.stringify(columns);
      localStorage.setItem(KEY, jsonString);
    } catch (error) {
      showNotification(
        'error',
        'Something went wrong',
        'Error saving your column preferences'
      );
    }
  },
  resetColumns: () => {
    localStorage.removeItem(KEY);
  },
};
