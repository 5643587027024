import {
  genOptions,
  route,
  fetchRequest,
  encodeFilter,
} from '../utils/request';
import storage from '../utils/storage';
import urlBuilder from '../utils/url-builder';
import organizationEndpoints from '../api/organization/organizationEndpoints';

// Gets users that are sworn only, also known as officers
export const getOfficers = (
  agencyId = '',
  filters = { includeUnsworn: 'false' }
) => {
  return organizationEndpoints.getUsersSearch(
    agencyId,
    filters.where ? filters.where.and : filters.fields
  );
};

//Gets all users in the system
export const getOfficersAndCivilians = (agencyId = '', filters = {}) => {
  return organizationEndpoints.getUsersSearch(
    agencyId,
    filters.where ? filters.where.and : filters.fields
  );
};

//Gets all users in the system
export const getOfficersAndCiviliansAcrossOUs = (
  agencyId = '',
  filters = {},
  useOUScope = true
) => {
  return organizationEndpoints.getUsersSearch(
    agencyId,
    filters.where ? filters.where.and : filters.fields,
    useOUScope
  );
};

export const getTrainees = (agencyId = '', filter = {}) => {
  const filters = JSON.stringify(filter);
  const token = storage.get('token');
  const url = route(
    urlBuilder(`/BenchmarkUsers/findWithoutDors`, null, { filters })
  );
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};

// TODO: Remove if no usages are found
export const getUsers = (
  includeDisabled = false,
  pageNumber,
  pageSize,
  sortBy = 'lastName',
  searchTerm
) => {
  return organizationEndpoints.getUsers(
    includeDisabled,
    pageNumber,
    pageSize,
    sortBy,
    searchTerm
  );
};

export const getUsersCount = ({ agencyId, filter: filters }) => {
  const filter = encodeFilter(filters);
  const url = route(
    urlBuilder(
      '/Agencies/:agencyId/BenchmarkUsers/count',
      { agencyId },
      { filter }
    )
  );
  const options = genOptions('GET', null, storage.get('token'));

  return fetchRequest(url, options);
};

export const getTrainingUsers = (agencyId = '', filters = {}) => {
  const filter = encodeFilter(filters);
  const token = storage.get('token');
  const url = route(
    urlBuilder(
      `/Agencies/:agencyId/trainingDashboard`,
      { agencyId },
      { filters: filter }
    )
  );
  const options = genOptions('GET', null, token);

  return fetchRequest(url, options);
};
