import styled from 'styled-components';
import { Table } from 'antd';

const StyledTable = styled(Table)`
  .ant-table table {
    border-bottom: none;
    background-color: #fff;

    color: #161616;
    font-weight: 400;
    font-size: 14px;
  }

  .ant-table-thead > tr > th {
    background: #e3e3e3;
    color: #000;
    font-weight: 600;
    font-size: 12px;
  }

  .ant-table-bordered .ant-table-thead > tr > th {
    &:not(:last-child) {
      border-right: 1px solid #d6d6d6;
    }
  }

  .ant-table-tbody > tr,
  .ant-table-tbody > tr > td,
  .ant-table-tbody > tr > td .text {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .ant-table-tbody > tr > td {
    border-right: none;
  }

  .ant-table-footer {
    border-top: none;
    background-color: #fff;
  }

  .ant-table-tbody > tr > td.ant-table-column-sort {
    background: #fff;
  }
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 24px;
  gap: 16px;
`;

export { StyledTable, LoaderContainer };
