import { authorizedRequest } from 'APP_ROOT/api/utils';
import { route, REACT_APP_FORMS_SERVICE_URI } from 'APP_ROOT/utils/request';
import urlBuilder from 'APP_ROOT/utils/url-builder';

/**
 * Request to /Agencies/:agencyId/formSubmissions/:formId/sections/:sectionId/reset
 * @param {string} agencyId
 * @param {string} formId
 * @param {string} sectionId
 * @return {Promise}
 */
export const resetCRTSection = (agencyId, formId, sectionId) => {
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/formSubmissions/:formId/sections/:sectionId/reset`,
      { agencyId, formId, sectionId }
    )
  );
  return authorizedRequest('PUT', url, '', '', true);
};

/**
 * Request to /Agencies/:agencyId/formSubmissions/:formId/sections/:sectionId/repeaters/:repeaterId/reset
 * @param {string} agencyId
 * @param {string} formId
 * @param {string} sectionId
 * @param {string} repeaterId
 * @return {Promise}
 */
export const resetCRTRepeaterSection = (
  agencyId,
  formId,
  sectionId,
  repeaterId
) => {
  const url = route(
    urlBuilder(
      `${REACT_APP_FORMS_SERVICE_URI}Agencies/:agencyId/formSubmissions/:formId/sections/:sectionId/repeaters/:repeaterId/reset`,
      { agencyId, formId, sectionId, repeaterId }
    )
  );
  return authorizedRequest('PUT', url, '', '', true);
};
